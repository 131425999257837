<template>
    <div class="education-items" v-if="schoolItems.length">
        <div
            @click="onClickItem(item.type)"
            class="item-wrapper"
            :key="`edu-${item.name}-${idx}`"
            v-for="(item, idx) in schoolItems"
        >
            <template>
                <div class="job-wrapper">
                    <div class="job-status">
                        <div>{{ $translate(item.type.toUpperCase()) }}</div>
                        <div class="job-button">
                            <div class="job-edit">
                                <img class="m-r-2" src="@/assets/images/icons/edit.png" width="16px" height="16px" />
                                <span class="edit-text" v-html="'수정'" />
                            </div>
                            <div class="job-delete" @click.stop="deleteItem(item)">
                                <img class="m-r-2" src="@/assets/images/icons/trash.png" width="16px" height="16px" />
                                <span class="delete-text" v-html="'삭제'" />
                            </div>
                        </div>
                    </div>
                    <div class="job-title">{{ item.name }}</div>
                    <div class="job-title" v-if="item.show_name">
                        <span v-if="item.college">{{ item.college }} </span>
                        <span v-if="item.major">{{ item.major }}</span>
                    </div>
                    <div class="job-description">
                        <span class="seperator" v-if="item.status !== null">{{ eduStatus[item.status] }}</span>
                        <!-- <span class="seperator" v-if="item.start">{{item.start}}년</span>
                        <span class="seperator" v-if="item.end">{{item.end}}년</span> -->
                        <span class="seperator" v-if="item.start > 0 && item.end > 0">{{
                            `${item.start}년 - ${item.end}년`
                        }}</span>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EducationItem',
    props: {
        schoolItems: Array,
    },
    methods: {
        onClickItem(school) {
            this.$unregisterBackHandler()
            this.$stackRouter.push({
                name: 'EditProfileDetailEducation',
                props: {
                    type: school,
                },
            })
        },
        showAddInfos(item) {
            return item.start && item.end
        },
        async deleteItem(item) {
            const idx = await this.$modal.basic({
                body: '내용을 삭제하시겠습니까?',
                buttons: [
                    {
                        label: '아니오',
                        class: 'btn-default',
                    },
                    {
                        label: '네',
                        class: 'btn-primary',
                    },
                ],
            })
            if (idx === 1) {
                if (item.type === 'high_school') {
                    this.$set(this.$store.getters.profile, `${item.type}_name`, '')
                    this.$set(this.$store.getters.profile, `${item.type}_status`, null)
                    this.$set(this.$store.getters.profile, `${item.type}_start`, null)
                    this.$set(this.$store.getters.profile, `${item.type}_end`, null)
                } else {
                    this.$set(this.$store.getters.profile, `${item.type}_u_id`, 0)
                    this.$set(this.$store.getters.profile, `${item.type}_u_name`, '')
                    this.$set(this.$store.getters.profile, `${item.type}_c_id`, 0)
                    this.$set(this.$store.getters.profile, `${item.type}_c_name`, '')
                    this.$set(this.$store.getters.profile, `${item.type}_m_id`, 0)
                    this.$set(this.$store.getters.profile, `${item.type}_m_name`, '')
                    this.$set(this.$store.getters.profile, `${item.type}_status`, null)
                    this.$set(this.$store.getters.profile, `${item.type}_start`, null)
                    this.$set(this.$store.getters.profile, `${item.type}_end`, null)
                }
            }
        },
    },
    computed: {
        eduStatus() {
            return {
                0: '졸업',
                1: '수료',
                2: '중퇴',
                3: '재학',
                4: '휴학',
                5: '편입재학',
                6: '편입졸업',
            }
        },
    },
}
</script>

<style scoped lang="scss">
.seperator:not(:last-child)::after {
    content: ' \A ';
    width: 4px;
    height: 4px;
    background-color: #94999e;
    border-radius: 100px;
    display: inline-block;
    padding: auto;
    vertical-align: middle;
    margin: 0 7px;
}
</style>
