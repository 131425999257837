<template>
    <div class="marriage section">
        <h3 class="title">
            <img class="m-r-2" src="@/assets/images/icons/Union.png" width="24px" height="24px" />
            <span>{{ $translate(section.title.toUpperCase()) }}</span>
        </h3>
        <div
            class="profile-item"
            v-for="(item, idx) in marriage"
            :key="`marriage-${idx}`"
            :class="{ required: $profile.required(item.key) }"
        >
            <template>
                <div
                    class="label"
                    :class="{ required: $profile.required(item.key) }"
                    v-html="$translate($case.toConst(item.key))"
                />
                <!--                <div class="label" v-html="item.key" />-->
                <div class="item" @click="onClickProfileItem(item.key)">
                    <div
                        class="btn-selector lines-2"
                        :class="{
                            'c-primary': getVal(item.key) !== 'NO_VALUE',
                            'no-input': getVal(item.key) === 'NO_VALUE',
                        }"
                        v-html="$translate(getVal(item.key))"
                    />
                    <i v-if="item.key === 'age'" class="material-icons lock">lock</i>
                </div>
                <div class="go-detail">
                    <img
                        class="m-r-6"
                        :src="require(`@/assets/images/icons/arrow_right.svg`)"
                        width="16px"
                        height="16px"
                    />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Marriage',
    props: ['section'],
    methods: {
        getVal(key) {
            if ([null, ''].includes(this.profile[key])) return 'NO_VALUE'

            return this.profile[key]
        },
        async onClickProfileItem(key) {
            const item = await this.$modal.custom({
                component: 'ModalProfileItemSelector',
                options: {
                    model: key,
                    profile: this.profile,
                    icon: 'close',
                    headerTitle: this.$translate('MARRIAGE'),
                },
            })
            if (!item || item.length === 0) return

            this.$set(this.profile, key, item.name)
            this.$profile.updateProfileOptions(key, item)
        },
    },
    computed: {
        profile() {
            return this.$store.getters.profile
        },
        marriage() {
            const profile = this.$store.getters.profile
            const marriageArr = []
            Object.keys(this.section.column).forEach(key => {
                const res = {}
                const value = this.section.column[key]
                res[value] = profile[value]
                res.key = value
                if (!res.key.includes('marry')) {
                    marriageArr.push(res)
                }
            })

            return marriageArr
        },
    },
}
</script>

<style scoped></style>
