var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"family section"},[_vm._m(0),_vm._l((_vm.family),function(item,idx){return _c('div',{key:`family-${idx}`,staticClass:"profile-item",class:{
            required: _vm.$profile.required(item.key) && item.key !== 'family',
            'family-list': item.key === 'family',
        }},[(item.key === 'family_intro')?[_c('div',{staticClass:"intro-wrapper w-100",on:{"click":function($event){return _vm.onClickProfileItem(item.key)}}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center"}},[_c('div',{staticClass:"label m-b-8",class:{ required: _vm.$profile.required(item.key) },staticStyle:{"width":"fit-content !important"},domProps:{"innerHTML":_vm._s(_vm.$translate(_vm.$case.toConst(item.key)))}}),_c('div',{staticClass:"flex-row"},[(item.pur)?_c('div',{staticClass:"pur-icon m-r-28"},[_vm._v("심사중")]):_vm._e()])]),_c('div',{staticClass:"w-100 intro",class:[_vm.profile.family_intro ? 'c-black' : 'c-grey-05'],domProps:{"innerHTML":_vm._s(_vm.profileFamilyIntro || _vm.placeholderFamilyIntro)}})])]:(item.key === 'family')?[_c('div',{staticClass:"list"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center"}},[_c('div',{staticClass:"label",class:{ required: _vm.$profile.required(item.key) },domProps:{"innerHTML":_vm._s(_vm.$translate(_vm.$case.toConst(item.key)))}}),(item.pur)?_c('div',{staticClass:"pur-icon"},[_vm._v("심사중")]):_vm._e()]),_c('FamilyItem',{attrs:{"familyType":_vm.familyType,"familyItems":_vm.familyItems}})],1),_c('div',{staticClass:"add-items"},_vm._l((Object.keys(_vm.familyType)),function(key){return _c('div',{key:`add-family-${key}`},[(_vm.showFamilyAdd(key))?_c('AddItemComponent',{staticClass:"m-b-8 must-item",attrs:{"text":_vm.$translate(_vm.familyType[key].name.toUpperCase()) + ' 정보'},on:{"click":function($event){return _vm.onClickAddFamily(_vm.familyType[key])}}}):_vm._e()],1)}),0)]:[_c('div',{staticClass:"label",class:{ required: _vm.$profile.required(item.key) },domProps:{"innerHTML":_vm._s(_vm.$translate(_vm.$case.toConst(item.key)))}}),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.onClickProfileItem(item.key)}}},[_c('div',{staticClass:"btn-selector",class:{
                        'c-primary': _vm.getVal(item.key) !== 'NO_VALUE',
                        'no-input': _vm.getVal(item.key) === 'NO_VALUE',
                    },domProps:{"innerHTML":_vm._s(_vm.$translate(_vm.getVal(item.key)))}}),(item.pur)?_c('div',{staticClass:"pur-icon"},[_vm._v("심사중")]):_vm._e()]),_c('div',{staticClass:"go-detail"},[_c('img',{staticClass:"m-r-6",attrs:{"src":require(`@/assets/images/icons/arrow_right.svg`),"width":"16px","height":"16px"}})])]],2)})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"title"},[_c('img',{staticClass:"m-r-2",attrs:{"src":require("@/assets/images/icons/family.png"),"width":"24px","height":"24px"}}),_c('span',[_vm._v("가족관계")])])
}]

export { render, staticRenderFns }