<template>
    <div class="family section">
        <h3 class="title">
            <img class="m-r-2" src="@/assets/images/icons/family.png" width="24px" height="24px" />
            <span>가족관계</span>
        </h3>
        <div
            class="profile-item"
            v-for="(item, idx) in family"
            :key="`family-${idx}`"
            :class="{
                required: $profile.required(item.key) && item.key !== 'family',
                'family-list': item.key === 'family',
            }"
        >
            <template v-if="item.key === 'family_intro'">
                <div class="intro-wrapper w-100" @click="onClickProfileItem(item.key)">
                    <div style="display: flex; justify-content: space-between; align-items: center">
                        <div
                            class="label m-b-8"
                            style="width: fit-content !important"
                            :class="{ required: $profile.required(item.key) }"
                            v-html="$translate($case.toConst(item.key))"
                        />
                        <div class="flex-row">
                            <div class="pur-icon m-r-28" v-if="item.pur">심사중</div>
                        </div>
                    </div>
                    <div
                        class="w-100 intro"
                        :class="[profile.family_intro ? 'c-black' : 'c-grey-05']"
                        v-html="profileFamilyIntro || placeholderFamilyIntro"
                    />
                </div>
            </template>
            <template v-else-if="item.key === 'family'">
                <div class="list">
                    <div style="display: flex; justify-content: space-between; align-items: center">
                        <div
                            class="label"
                            :class="{ required: $profile.required(item.key) }"
                            v-html="$translate($case.toConst(item.key))"
                        />
                        <div class="pur-icon" v-if="item.pur">심사중</div>
                    </div>
                    <FamilyItem :familyType="familyType" :familyItems="familyItems" />
                </div>
                <div class="add-items">
                    <div v-for="key in Object.keys(familyType)" :key="`add-family-${key}`">
                        <AddItemComponent
                            class="m-b-8 must-item"
                            v-if="showFamilyAdd(key)"
                            :text="$translate(familyType[key].name.toUpperCase()) + ' 정보'"
                            @click="onClickAddFamily(familyType[key])"
                        />
                    </div>
                </div>
            </template>
            <template v-else>
                <div
                    class="label"
                    :class="{ required: $profile.required(item.key) }"
                    v-html="$translate($case.toConst(item.key))"
                />
                <div class="item" @click="onClickProfileItem(item.key)">
                    <div
                        class="btn-selector"
                        :class="{
                            'c-primary': getVal(item.key) !== 'NO_VALUE',
                            'no-input': getVal(item.key) === 'NO_VALUE',
                        }"
                        v-html="$translate(getVal(item.key))"
                    />
                    <div class="pur-icon" v-if="item.pur">심사중</div>
                </div>
                <div class="go-detail">
                    <img
                        class="m-r-6"
                        :src="require(`@/assets/images/icons/arrow_right.svg`)"
                        width="16px"
                        height="16px"
                    />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import FamilyItem from '@/routes/my-page/components/FamilyItem'

export default {
    name: 'Family',
    props: ['section'],
    components: {
        FamilyItem,
    },
    computed: {
        profile() {
            return this.$store.getters.profile
        },
        profileUpdateRequests() {
            return this.$store.getters.profileUpdateRequests
        },
        family() {
            const profile = this.profile
            const familyArr = []
            Object.keys(this.section.column).forEach(key => {
                const res = {}
                const value = this.section.column[key]
                if (value === 'hometown') {
                    res[`${value}_state`] = profile[`${value}_state`]
                    res.pur = !!this.profileUpdateRequests.hometown_state_id
                } else if (value === 'family_relations') {
                    res[value] = {
                        brother: profile.brother_count,
                        sister: profile.sister_count,
                        order: profile.order_of_children,
                    }
                    res.pur = !!(
                        this.profileUpdateRequests.brother_count ||
                        this.profileUpdateRequests.sister_count ||
                        this.profileUpdateRequests.order_of_children
                    )
                } else {
                    res[value] = profile[value]
                    res.pur = !!this.profileUpdateRequests[value]
                }
                res.key = value
                familyArr.push(res)
            })

            return familyArr
        },
        familyItems() {
            const family = this.profile.family || []

            if (family.length) family.sort((a, b) => a.family_type - b.family_type)
            return this.profile.family || []
        },
        familyType() {
            return this.$profile.familyType
        },
        profileFamilyIntro() {
            if (!this.profile.family_intro) return false

            return this.profile.family_intro.split(/\n/).join('<br>')
        },
        placeholderFamilyIntro() {
            return '- 가족의 분위기, 성장 환경, 문화<br>- 부모님의 학력, 직업<br>- 결혼시 지원 가능한 부분, 노후대비, 상속 예정 내용 등을 입력해주세요.'
        },
    },
    methods: {
        getVal(key) {
            if (key === 'family_relations') {
                const { brother_count: bro, sister_count: sis, order_of_children: ooc } = this.profile
                return !ooc
                    ? 'NO_VALUE'
                    : `${Number(bro) ? `${bro}남` : ''} ${Number(sis) ? `${sis}녀` : ''} 중 ${ooc}째`
            }

            if (key === 'parent_status') {
                return (this.$profile.parentStatus[this.profile[key]] || {}).title || 'NO_VALUE'
            }

            if (key === 'hometown') {
                const prefix = key !== 'state' ? `${key}_` : ''
                if (!this.profile[`${prefix}state`]) return 'NO_VALUE'

                if (this.profile[`${prefix}station_name`]) {
                    return `${this.profile[`${prefix}station_name`]}`
                }
                if (this.profile[`${prefix}region_name`]) {
                    return `${this.profile[`${prefix}region_name`]}`
                }

                if ((this.profile[`${prefix}state`] || {}).is_station) {
                    return `${this.profile[`${prefix}state`].name} ${this.profile[`${prefix}station`].name}역`
                } else {
                    return `${this.profile[`${prefix}state`].name} ${this.profile[`${prefix}region`].district}`
                }
            }

            if (this.$profile.needMoneyInput(key)) {
                if (this.profile[key] === null) return 'NO_VALUE'

                return this.profile[key] > 0
                    ? this.$krwParsed(this.profile[key])
                    : `${this.$translate(this.$case.toConst(key)).toUpperCase()} 없음`
            }

            return this.profile[key] || 'NO_VALUE'
        },
        async onClickProfileItem(key) {
            if (key === 'hometown') await this.$profile.editState(key)

            // stack-router
            this.$unregisterBackHandler()
            if (this.$profile.needMoneyInput(key)) {
                this.$stackRouter.push({
                    name: 'EditProfileDetailFinance',
                    props: {
                        type: key,
                        initialVal: this.profile[key],
                        section: this.section.title,
                    },
                })

                return
            }
            if (key === 'family_relations') {
                this.$stackRouter.push({
                    name: 'EditProfileDetailFamilyRelations',
                    props: {
                        type: key,
                    },
                })

                return
            }
            if (key === 'parent_status') {
                this.$stackRouter.push({
                    name: 'EditProfileDetailParentStatus',
                    props: {
                        type: key,
                    },
                })

                return
            }

            if (key === 'family_intro') {
                this.$stackRouter.push({
                    name: 'EditProfileText',
                    props: {
                        type: key,
                        section: this.section.title,
                    },
                })
            }
        },
        showFamilyAdd(key) {
            const userSiblingCount = this.profile.sister_count + this.profile.brother_count - 1
            if (Number(key) === 2) {
                const currentSiblingCount = this.familyItems.filter(f => f.family_type === 2).length
                if (userSiblingCount - currentSiblingCount) return true
                return false
            }
            return !this.familyItems.some(item => item.family_type === Number(key))
        },
        onClickAddFamily(familyType) {
            this.$unregisterBackHandler()
            this.$stackRouter.push({
                name: 'EditProfileDetailFamily',
                props: {
                    familyType: familyType,
                },
            })
        },
    },
}
</script>
