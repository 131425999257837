<template>
    <div class="only-manager section">
        <h3 class="title">
            <img class="m-r-2" src="@/assets/images/icons/manager-talk.png" width="24px" height="24px" />
            <span>{{ $translate(section.title.toUpperCase()) }}</span>
        </h3>
        <div class="profile-item">
            <template>
                <div class="intro w-100">
                    <TextareaWithX @input="onInputIntro" :placeholder="placeholder" v-model="content" />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OnlyToManager',
    props: ['section'],
    data: () => ({
        content: null,
    }),
    computed: {
        profile() {
            return this.$store.getters.profile
        },
        placeholder() {
            return (
                '본인 입으로 말하기 민망하지만, 이성에게 어필이 될만한 포인트를 모두 적어주세요. 매니저가 경우에 따라 센스있게 회원님을 어필하는데 사용할 수 있어요. \n' +
                '(본인 혹은 집안의 경제력, 성격적/신체적 매력 등)'
            )
        },
        items() {
            const profile = this.$store.getters.profile || {}
            const etcArr = []
            Object.keys(this.section.column).forEach(key => {
                const res = {}
                const value = this.section.column[key]
                res[value] = profile[value]
                res.key = value
                etcArr.push(res)
            })

            return etcArr
        },
    },
    mounted() {
        this.content = this.profile.only_to_manager || null
    },
    methods: {
        onInputIntro() {
            this.$set(this.profile, 'only_to_manager', this.content)
            // this.profileChanged = true
        },
    },
}
</script>

<style scoped lang="scss">
::v-deep .textarea-with-x {
    height: 180px;

    textarea::placeholder {
        line-height: 24px;
    }
}
</style>
