<template>
    <div class="education section">
        <div class="section-header">
            <h3 class="title required">
                <img class="m-r-2" src="@/assets/images/icons/edu.png" width="24px" height="24px" />
                <span>{{ $translate(section.title.toUpperCase()) }}</span>
            </h3>
            <div class="pur-icon m-l-8" v-if="needPur">심사중</div>
        </div>
        <div class="add-items">
            <AddItemComponent
                class="m-b-8"
                v-for="school in schoolCanEdit"
                :key="school"
                :text="$translate(school.toUpperCase())"
                :needMoreInfo="needMoreInfo(school)"
                :requiredItem="requiredItem(school)"
                @click="addSchoolInfo(school)"
                :class="{ 'must-item': school === 'bachelor' }"
            />
            <!-- :class="{'item-required' : (school==='high_school' || school==='bachelor')
                        }" -->
        </div>
        <div class="list">
            <EducationItem :schoolItems="schoolItems" />
        </div>
    </div>
</template>

<script>
import EducationItem from '@/routes/my-page/components/EducationItem'

export default {
    name: 'Education',
    props: ['section'],
    components: {
        EducationItem,
    },
    computed: {
        profile() {
            return this.$store.getters.profile
        },
        needPur() {
            const {
                doctor_u_name: doctor,
                master_u_name: master,
                bachelor_u_name: bachelor,
                high_school_name: high,
            } = this.$store.getters.profileUpdateRequests
            return doctor || master || bachelor || high
        },
        userSchools() {
            let schools = ['bachelor', 'master', 'doctor'].filter(type => this.profile[`${type}_u_name`])
            if (this.profile.high_school_name) {
                schools = ['high_school'].concat(schools)
            }

            return schools
        },
        schoolItems() {
            const items = []
            this.userSchools.forEach(sType => {
                const name = sType === 'high_school' ? this.profile.high_school_name : this.profile[`${sType}_u_name`]
                //     : `${this.profile[`${sType}_u_name`]} ${this.profile[`${sType}_c_name`]} ${
                //           this.profile[`${sType}_m_name`]
                //       }`

                if (!this.needMoreInfo(sType)) {
                    items.push({
                        type: sType,
                        name: name,
                        college: this.profile[`${sType}_c_name`],
                        major: this.profile[`${sType}_m_name`],
                        status: this.profile[`${sType}_status`],
                        start: this.profile[`${sType}_start`],
                        end: this.profile[`${sType}_end`],
                        show_name: this.profile.privacy_setting[`show_${sType}_u_name`],
                    })
                }
            })

            return items
        },
        schoolCanEdit() {
            return ['high_school', 'bachelor', 'master', 'doctor'].filter(type => {
                const alreadyEdit = this.schoolItems.find(item => item.type === type)
                // if (alreadyEdit) return !(alreadyEdit.start && alreadyEdit.end)
                if (alreadyEdit) return false
                return !this.schoolItems.some(item => item.type === type)
            })
        },
        requiredItem() {
            return school => {
                if (school === 'bachelor') {
                    return true
                }
                return false
            }
        },
    },
    methods: {
        addSchoolInfo(school) {
            this.$unregisterBackHandler()
            this.$stackRouter.push({
                name: 'EditProfileDetailEducation',
                props: {
                    type: school,
                },
            })
        },
        needMoreInfo(sType) {
            if (!this.profile[`${sType}_u_name`]) return false

            // return !(this.profile[`${sType}_start`] && this.profile[`${sType}_end`])
        },
    },
}
</script>

<style scoped></style>
