<template>
    <div class="personal section">
        <h3 class="title">프로필</h3>
        <div
            class="profile-item"
            v-for="(item, idx) in personal"
            :key="`personal-${idx}`"
            @click="onClickProfileItem(item.key)"
        >
            <template v-if="item.key === 'intro'">
                <div class="intro-wrapper w-100" @click="onClickProfileItem(item.key)">
                    <div style="display: flex; justify-content: space-between; align-items: center">
                        <div
                            class="label m-b-8"
                            style="width: fit-content !important"
                            v-html="$translate($case.toConst(item.key))"
                        />
                        <div class="flex-row">
                            <div class="pur-icon" v-if="item.pur">심사중</div>
                        </div>
                    </div>
                    <div
                        class="w-100 intro"
                        v-html="profileIntro || placeholderIntro"
                        :class="{
                            'c-black': profile.intro,
                            'c-grey': !profile.intro,
                        }"
                    />
                </div>
            </template>
            <template v-else>
                <div class="profile-content">
                    <div class="label p-l-20 m-b-4" v-html="$translate($case.toConst(item.key))" />
                    <div class="item">
                        <!-- <div class="flex-row"> -->
                        <div
                            class="btn-selector hi p-l-20"
                            :class="{
                                'input-filled': getVal(item.key) !== 'NO_VALUE',
                                'no-input': getVal(item.key) === 'NO_VALUE',
                            }"
                            v-html="$translate(getVal(item.key, item.pur))"
                        />
                        <!-- <img
                                v-if="item.key === 'age'"
                                class="m-r-2 lock"
                                :src="require(`@/assets/images/icons/lock.png`)"
                                width="16px"
                                height="16px"
                            /> -->
                        <!-- </div> -->
                    </div>
                </div>
                <div class="go-detail">
                    <div class="pur-icon" v-if="item.pur || purOngoing.includes(item.key)">심사중</div>

                    <img
                        v-if="item.key !== 'age'"
                        class="m-r-6"
                        :src="require(`@/assets/images/icons/arrow_right.svg`)"
                        width="16px"
                        height="16px"
                    />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Personal',
    props: ['section'],
    mounted() {
        if (this.profileUpdateRequest.bachelor_u_name) {
            this.profile.bachelor_u_name = this.profileUpdateRequest.bachelor_u_name
        }
        if (this.profileUpdateRequest.bachelor_m_name) {
            this.profile.bachelor_m_name = this.profileUpdateRequest.bachelor_m_name
        }
    },
    methods: {
        getVal(key) {
            if ([null, ''].includes(this.profile[key])) return 'NO_VALUE'

            if (key === 'state') {
                const prefix = key !== 'state' ? `${key}_` : ''
                if (!this.profile[`${prefix}state`]) return 'NO_VALUE'

                if (this.profile[`${prefix}station_name`]) {
                    return `${this.profile[`${prefix}station_name`]}`
                }
                if (this.profile[`${prefix}region_name`]) {
                    return `${this.profile[`${prefix}region_name`]}`
                }

                if ((this.profile[`${prefix}state`] || {}).is_station) {
                    return `${this.profile[`${prefix}state`].name} ${this.profile[`${prefix}station`].name}역`
                } else {
                    return `${this.profile[`${prefix}state`].name} ${this.profile[`${prefix}region`].district}`
                }
            }
            if (key === 'health_status') {
                return (this.$profile.healthStatus[this.profile[key]] || {}).title || 'NO_VALUE'
            }
            if (key === 'age') {
                return this.$options.filters.asAge(this.profile.birthday)
            }
            if (key === 'marry') {
                if (this.profileUpdateRequest.marry) {
                    this.profile.marry = this.profileUpdateRequest.marry
                }
                return this.profile.marry ? '돌싱' : '초혼'
            }
            if (key === 'smoking') {
                return this.profile.smoking ? '흡연' : '비흡연'
            }
            if (key === 'height') {
                return `${this.profile[key]}cm`
            }
            if (key === 'jobDetail') {
                if (this.profileUpdateRequest.company) {
                    this.profile.company[0].job_name = this.profileUpdateRequest.company[0].job_name
                }
                return this.profile.company[0] ? this.profile.company[0].job_name : ''
            }
            if (key === 'jobCategoryName') {
                if (this.profileUpdateRequest.company) {
                    this.profile.company[0].job_category_name = this.profileUpdateRequest.company[0].job_category_name
                }
                return this.profile.company[0] ? this.profile.company[0].job_category_name : ''
            }
            if (key === 'company') {
                if (this.profileUpdateRequest.company) {
                    this.profile.company[0].company_name = this.profileUpdateRequest.company[0].company_name
                }
                return this.profile.company[0] ? this.profile.company[0].company_name : ''
            }
            if (key === 'jobLocation') {
                if (this.profileUpdateRequest.company) {
                    this.profile.company[0].region_shorten = this.profileUpdateRequest.company[0].region_shorten
                }
                return this.profile.company[0] ? this.profile.company[0].region_shorten : ''
            }
            if (key === 'school') {
                return this.profile.school.name ? this.profile.school.name : ''
            }
            if (key === 'bachelor') {
                let uName
                if (this.profileUpdateRequest.bachelor_u_name) {
                    uName = this.profileUpdateRequest.bachelor_u_name
                    this.profile.bachelor_u_name = this.profileUpdateRequest.bachelor_u_name
                } else {
                    uName = this.profile.bachelor_u_name
                }

                let mName
                if (this.profileUpdateRequest.bachelor_m_name) {
                    mName = this.profileUpdateRequest.bachelor_m_name
                    this.profile.bachelor_m_name = this.profileUpdateRequest.bachelor_m_name
                } else {
                    mName = this.profile.bachelor_m_name
                }
                return this.profile.bachelor_u_name ? uName + ' ' + mName : ''
            }
            if (key === 'master') {
                let uName
                if (this.profileUpdateRequest.master_u_name) {
                    uName = this.profileUpdateRequest.master_u_name
                    this.profile.master_u_name = this.profileUpdateRequest.master_u_name
                } else {
                    uName = this.profile.master_u_name
                }

                let mName
                if (this.profileUpdateRequest.master_m_name) {
                    mName = this.profileUpdateRequest.master_m_name
                    this.profile.master_m_name = this.profileUpdateRequest.master_m_name
                } else {
                    mName = this.profile.master_m_name
                }
                return this.profile.master_u_name ? uName + ' ' + mName : ''
            }
            if (key === 'doctor') {
                let uName
                if (this.profileUpdateRequest.doctor_u_name) {
                    uName = this.profileUpdateRequest.doctor_u_name
                    this.profile.doctor_u_name = this.profileUpdateRequest.doctor_u_name
                } else {
                    uName = this.profile.doctor_u_name
                }

                let mName
                if (this.profileUpdateRequest.doctor_m_name) {
                    mName = this.profileUpdateRequest.doctor_m_name
                    this.profile.doctor_m_name = this.profileUpdateRequest.doctor_m_name
                } else {
                    mName = this.profile.doctor_m_name
                }
                return this.profile.doctor_u_name ? uName + ' ' + mName : ''
            }
            if (key === 'income') {
                if (this.profileUpdateRequest.income) {
                    this.profile.income = this.profileUpdateRequest.income
                }
                return this.profile.income ? this.krwParsedIncome : ''
            }
            if (key === 'asset') {
                if (this.profileUpdateRequest.asset) {
                    this.profile.asset = this.profileUpdateRequest.asset
                }
                return this.profile.asset ? this.krwParsedAsset : ''
            }

            return this.profile[key]
        },
        async onClickProfileItem(key) {
            // 모아찍기(1차지역->2차지역, 학부->대학->학과) 필요
            if (key === 'age') return

            if (key === 'intro') {
                this.$unregisterBackHandler()
                this.$stackRouter.push({
                    name: 'EditProfileText',
                    props: {
                        type: key,
                        section: this.section.title,
                    },
                })

                return
            }

            if (this.purOngoing.includes(key)) {
                this.$modal.basic({
                    body: '심사가 완료된 후에 수정할 수 있습니다.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
            } else {
                if (key === 'state') {
                    this.$profile.editState(key)
                } else if (key === 'health_status') {
                    this.$stackRouter.push({
                        name: 'EditProfileDetailHealth',
                        props: {
                            type: key,
                        },
                    })
                } else if (
                    key === 'jobDetail' ||
                    key === 'jobCategoryName' ||
                    key === 'company' ||
                    key === 'jobLocation'
                ) {
                    if (this.$profile.jobFreePage(this.profile.company[0].job_category_id)) {
                        this.$stackRouter.push({
                            name: 'EditProfileDetailJobFree',
                            props: {
                                job_category: {
                                    id: this.profile.company[0].job_category_id,
                                    name: this.profile.company[0].job_category_name,
                                },
                                edit: { ...this.profile.company[0] },
                            },
                        })

                        return
                    }
                    this.$stackRouter.push({
                        name: 'JobDetail',
                        props: {
                            type: 'A',
                            job_category: {
                                id: this.profile.company[0].job_category_id,
                                name: this.profile.company[0].job_category_name,
                            },
                            profile: this.profile,
                            edit: { ...this.profile.company[0] },
                        },
                    })
                } else if (key === 'bachelor' || key === 'master' || key === 'doctor') {
                    this.$unregisterBackHandler()
                    this.$stackRouter.push({
                        name: 'EditProfileDetailEducation',
                        props: {
                            type: key,
                            profile: this.profile,
                        },
                    })
                } else {
                    // 모아찍기 불필요
                    const item = await this.$modal.custom({
                        component: 'ModalProfileItemSelector',
                        options: {
                            model: key,
                            profile: this.profile,
                            icon: 'close',
                            headerTitle: this.$translate('PERSONAL'),
                        },
                    })
                    if (!item || item.length === 0) return

                    // profile_options을 통해 값을 업데이트 해야 하는 요소들과 그렇지 않은 것으로 분기
                    const needProfileOptions = this.$profile.needProfileOption(key)
                    const multipleChoice = this.$profile.multipleChoice(key)

                    if (needProfileOptions) {
                        // 다중 선택이 가능한 옵션들과 그렇지 않은 것으로 분기
                        if (multipleChoice) {
                            this.$set(this.profile, key, item.map(i => i.name).join(', '))
                        } else {
                            this.$set(this.profile, key, item.name)
                        }

                        this.$profile.updateProfileOptions(key, item)
                    } else {
                        if (key === 'smoking') {
                            this.$set(this.profile, key, item.name === '흡연')
                            return
                        }
                        if (key === 'marry') {
                            this.$set(this.profile, key, item.id)
                            return
                        }
                        if (key === 'marry_plan') {
                            this.$set(this.profile, key, item.name)
                            return
                        }
                        this.$set(this.profile, key, item)
                    }
                }
            }
        },
        // async initEducation() {
        //     if (this.profileUpdateRequest.bachelor_u_name) {
        //         this.profile.bachelor_u_name = this.profileUpdateRequest.bachelor_u_name
        //     }
        //     if (this.profileUpdateRequest.bachelor_m_name) {
        //         this.profile.bachelor_m_name = this.profileUpdateRequest.bachelor_m_name
        //     }
        // }
    },
    computed: {
        profile() {
            return this.$store.getters.profile
        },
        profileUpdateRequest() {
            return this.$store.getters.profileUpdateRequests
        },
        profileIntro() {
            if (!this.profile.intro) return false

            return this.profile.intro.split(/\n/).join('<br>')
        },
        placeholderIntro() {
            return '입력해주세요'
        },
        personal() {
            const profile = this.$store.getters.profile || {}
            const personalArr = []
            Object.keys(this.section.column).forEach(key => {
                const res = {}
                const value = this.section.column[key]
                if (value === 'age') {
                    res[value] = this.$options.filters.asAge(profile.birthday)
                } else {
                    res[value] = profile[value]
                }
                res.key = value

                // pur
                if (value === 'state') {
                    res.pur = !!this.profileUpdateRequest.state_id
                } else {
                    res.pur = ![null, undefined].includes(this.profileUpdateRequest[value])
                }

                personalArr.push(res)
            })

            if ([6, 8, 9].includes(personalArr[8].school.id)) {
                personalArr.splice(9, 3)
            } else if ([7, 5, 4].includes(personalArr[8].school.id)) {
                personalArr.splice(10, 2)
            } else if ([3, 2].includes(personalArr[8].school.id)) {
                personalArr.splice(11, 1)
            }

            return personalArr
        },
        purOngoing() {
            const pur = []
            if (this.profileUpdateRequest.school_name) {
                pur.push('school')
            }
            if (this.profileUpdateRequest.bachelor_m_name || this.profileUpdateRequest.bachelor_u_name) {
                pur.push('bachelor')
            }
            if (this.profileUpdateRequest.master_m_name || this.profileUpdateRequest.masetr_u_name) {
                pur.push('master')
            }
            if (this.profileUpdateRequest.doctor_m_name || this.profileUpdateRequest.doctor_u_name) {
                pur.push('doctor')
            }
            if (this.profileUpdateRequest.company) {
                pur.push('jobDetail', 'jobCategoryName', 'company', 'jobLocation')
            }
            if (this.profileUpdateRequest.income) {
                pur.push('income')
            }
            if (this.profileUpdateRequest.asset) {
                pur.push('asset')
            }
            if (this.profileUpdateRequest.marry) {
                pur.push('marry')
            }
            return pur
        },
        krwParsedIncome() {
            const value = this.profile.income
            const val = String(value)
            if (val.length > 8) {
                const zo = val.slice(0, val.length - 8)
                const billion = val.slice(val.length - 8, val.length - 4)
                const rest = val.slice(val.length - 4, val.length)

                return `${zo}조 ${Number(billion) ? `${Number(billion).toLocaleString()}억 ` : ''}${
                    Number(rest) ? `${Number(rest).toLocaleString()}만원` : ''
                }`
            }
            if (val.length > 4) {
                const billion = val.slice(0, val.length - 4)
                const rest = val.slice(val.length - 4, val.length)

                return `${Number(billion) ? `${Number(billion).toLocaleString()}억 ` : ''}${
                    Number(rest) ? `${Number(rest).toLocaleString()}만원` : ''
                }`
            }

            return `${value.toLocaleString()} 만원`
        },
        krwParsedAsset() {
            const value = this.profile.asset
            const val = String(value)
            if (val.length > 8) {
                const zo = val.slice(0, val.length - 8)
                const billion = val.slice(val.length - 8, val.length - 4)
                const rest = val.slice(val.length - 4, val.length)

                return `${zo}조 ${Number(billion) ? `${Number(billion).toLocaleString()}억 ` : ''}${
                    Number(rest) ? `${Number(rest).toLocaleString()}만원` : ''
                }`
            }
            if (val.length > 4) {
                const billion = val.slice(0, val.length - 4)
                const rest = val.slice(val.length - 4, val.length)

                return `${Number(billion) ? `${Number(billion).toLocaleString()}억 ` : ''}${
                    Number(rest) ? `${Number(rest).toLocaleString()}만원` : ''
                }`
            }

            return `${value.toLocaleString()} 만원`
        },
    },
}
</script>
<style lang="scss" scoped>
.profile-item {
    border-top: 1px solid #dcdcdc;

    &:last-child {
        border-bottom: 1px solid #dcdcdc;
    }
}
</style>
