<template>
    <div class="career-items">
        <div class="item-wrapper" :key="idx" v-for="(c, idx) in company" @click="onClickProfileItem(c, idx)">
            <template>
                <div class="job-wrapper">
                    <div class="job-status">
                        <div>{{ c.employment_status ? '현직장' : '전직장' }}</div>
                        <div class="job-button">
                            <div class="job-edit">
                                <img class="m-r-2" src="@/assets/images/icons/edit.png" width="16px" height="16px" />
                                <span class="edit-text" v-html="'수정'" />
                            </div>
                            <div class="job-delete" @click.stop="deleteItem(c)">
                                <img class="m-r-2" src="@/assets/images/icons/trash.png" width="16px" height="16px" />
                                <span class="delete-text" v-html="'삭제'" />
                            </div>
                        </div>
                    </div>
                    <div class="job-title">{{ c.company_name }}</div>

                    <div class="job-description" v-if="$profile.jobFreePage(c.job_category_id)">
                        <span>{{ c.job_category_name }}</span>
                        <p v-html="parseFreeJobDesc(c.company_task)" />
                    </div>

                    <div v-else>
                        <div class="job-description">
                            <!-- <span>{{ c.job_category_name }}</span>
                            <span>•</span>
                            <span>{{ c.company_department }}</span>
                            <span>•</span>
                            <span>{{ c.job_name }}</span>
                            <span>•</span>
                            <span>{{ c.company_rank }}</span> -->
                            <span v-for="(info, idx) in jobInfo(c)" :key="idx" class="seperator">
                                {{ c[info] }}
                            </span>
                        </div>
                        <div class="job-description" v-if="c.company_task">{{ c.company_task }}</div>
                        <div class="job-description">
                            <!-- {{ ($profile.employmentType[c.employment_type] || {}).title }} • {{ c.region_shorten }} -->
                            <span class="seperator" v-if="c.employment_status !== null">
                                {{ ($profile.employmentType[c.employment_type] || {}).title }}
                            </span>
                            <span class="seperator" v-if="c.region_shorten">
                                {{ c.region_shorten }}
                            </span>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CareerItem',
    props: ['value', 'keys', 'required'],
    data: () => ({
        // profile: {},
        profileChanged: false,
    }),
    beforeDestroy() {
        if (this.profileChanged) this.$toast.success('SAVED')
    },
    computed: {
        company() {
            const company = this.profile.company || []

            if (company.length) {
                company.sort((a, b) => {
                    return b.employment_status - a.employment_status
                })
            }
            return company
        },
        verificationType() {
            return this.$store.getters.me.profile.verification_type
        },
        profile() {
            return this.$store.getters.profile
        },
        jobInfo() {
            return company => {
                const fields = ['job_category_name', 'company_department', 'job_name', 'company_rank']
                const items = []
                fields.forEach(cate => {
                    if (company[cate]) {
                        items.push(cate)
                    }
                })
                return items
            }
        },
    },
    methods: {
        async onClickProfileItem(c, idx) {
            this.$unregisterBackHandler()
            if (this.$profile.jobFreePage(c.job_category_id)) {
                this.$stackRouter.push({
                    name: 'EditProfileDetailJobFree',
                    props: {
                        job_category: { id: c.job_category_id, name: c.job_category_name },
                        edit: { ...c, idx },
                    },
                })

                return
            }
            this.$stackRouter.push({
                name: 'JobDetail',
                props: {
                    type: 'A',
                    job_category: { id: c.job_category_id, name: c.job_category_name },
                    profile: this.profile,
                    edit: { ...c, idx },
                },
            })
        },
        parseFreeJobDesc(val) {
            if (val) {
                return val.split(/\n/).join('<br>')
            }

            return ''
        },
        async deleteItem(item) {
            const idx = await this.$modal.basic({
                body: '내용을 삭제하시겠습니까?',
                buttons: [
                    {
                        label: '아니오',
                        class: 'btn-default',
                    },
                    {
                        label: '네',
                        class: 'btn-primary',
                    },
                ],
            })
            if (idx === 1) {
                const { company } = this.$store.getters.profile

                this.$set(
                    this.$store.getters.profile,
                    'company',
                    company.filter(c => c.id !== item.id)
                )
            }
        },
    },
}
</script>
<style scoped lang="scss">
.dot:not(:last-child) {
    width: 10px;
    height: 10px;
}

.seperator:not(:last-child)::after {
    content: '\A';
    width: 4px;
    height: 4px;
    background-color: #94999e;
    border-radius: 100px;
    display: inline-block;
    padding: auto;
    vertical-align: middle;
    white-space: nowrap;
    // word-break:nowrap;
    margin: 0 7px;
}
</style>
