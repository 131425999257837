<template>
    <div class="career section">
        <div class="section-header">
            <h3 class="title required">
                <img class="m-r-2" src="@/assets/images/icons/company.png" width="24px" height="24px" />
                <span>{{ $translate(section.title.toUpperCase()) }}</span>
            </h3>
            <div class="pur-icon m-l-8" v-if="needPur">심사중</div>
        </div>
        <div class="add-items">
            <AddItemComponent
                class="m-b-8"
                text="직장/직업"
                @click="onClickProfileItem"
                :class="{ 'must-item': !profile.company.length }"
            />
            <!-- :class="{'item-required' : !profile.company.length}" -->
        </div>
        <div class="career-list">
            <CareerItem :keys="section" />
        </div>
    </div>
</template>

<script>
import CareerItem from '@/routes/my-page/components/CareerItem'

export default {
    name: 'Career',
    components: {
        CareerItem,
    },
    computed: {
        profile() {
            return this.$store.getters.profile
        },
        pur() {
            return this.$store.getters.profileUpdateRequests
        },
        needPur() {
            return !!this.pur.company
        },
    },
    props: ['section'],
    methods: {
        async onClickProfileItem() {
            const item = await this.$modal.custom({
                component: 'ModalProfileItemSelector',
                options: {
                    model: 'jobCategory',
                    profile: this.profile,
                    icon: 'close',
                    headerTitle: '직장/직업사항',
                },
            })
            if (!item) return

            this.$unregisterBackHandler()
            if (this.$profile.jobFreePage(item.id)) {
                this.$stackRouter.push({
                    name: 'EditProfileDetailJobFree',
                    props: { job_category: item, profile: this.profile },
                })
            } else {
                this.$stackRouter.push({
                    name: 'JobDetail',
                    props: { type: 'A', job_category: item, profile: this.profile },
                })
            }
        },
    },
}
</script>

<style scoped></style>
