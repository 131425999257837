<template>
    <div class="income section">
        <h3 class="title">
            <img class="m-r-2" src="@/assets/images/icons/money.png" width="24px" height="24px" />
            <span>{{ $translate(section.title.toUpperCase()) }}</span>
        </h3>
        <div
            class="profile-item"
            v-for="(item, idx) in financial"
            :key="`financial-${idx}`"
            :class="{ required: $profile.required(item.key) }"
        >
            <template v-if="item.key === 'finance'">
                <div class="intro-wrapper w-100" @click="onClickProfileItem(item.key)">
                    <div style="display: flex; justify-content: space-between; align-items: center">
                        <div
                            class="label m-b-8"
                            style="width: fit-content !important"
                            :class="{ required: $profile.required(item.key) }"
                            v-html="$translate($case.toConst(item.key))"
                        />
                        <div class="flex-row">
                            <div class="pur-icon" v-if="item.pur">심사중</div>
                        </div>
                    </div>
                    <div
                        class="w-100 intro"
                        :class="[profile.finance ? 'c-black' : 'c-grey-05']"
                        v-html="profileFinanceIntro || placeholderFinance"
                    />
                </div>
            </template>
            <template v-else>
                <div
                    class="label"
                    :class="{ required: $profile.required(item.key) }"
                    v-html="$translate($case.toConst(item.key))"
                />
                <div class="item" @click="onClickProfileItem(item.key)">
                    <div
                        class="btn-selector"
                        :class="{
                            'c-primary': getVal(item.key) !== 'NO_VALUE',
                            'no-input': getVal(item.key) === 'NO_VALUE',
                        }"
                        v-html="$translate(getVal(item.key))"
                    />
                    <div class="pur-icon" v-if="item.pur">심사중</div>
                </div>
                <div class="go-detail">
                    <img
                        class="m-r-6"
                        :src="require(`@/assets/images/icons/arrow_right.svg`)"
                        width="16px"
                        height="16px"
                    />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Financial',
    props: ['section'],
    computed: {
        profile() {
            return this.$store.getters.profile
        },
        profileUpdateRequests() {
            return this.$store.getters.profileUpdateRequests
        },
        profileFinanceIntro() {
            if (!this.profile.finance) return false

            return this.profile.finance.split(/\n/).join('<br>')
        },
        placeholderFinance() {
            return '구체적으로 어떤것들을 하는지 적어주세요. 상속 예정인 내용을 적어주셔도 좋아요. (주식, 코인, 부동산 등)'
        },
        financial() {
            const profile = this.$store.getters.profile
            const financialArr = []
            Object.keys(this.section.column).forEach(key => {
                const res = {}
                const value = this.section.column[key]
                res[value] = profile[value]
                res.key = value

                if (value === 'house_payment') res.pur = this.profileUpdateRequests.house_owner !== undefined
                else if (value === 'car')
                    res.pur =
                        this.profileUpdateRequests.car_type !== undefined ||
                        this.profileUpdateRequests.car_model !== undefined
                else res.pur = !!this.profileUpdateRequests[value]

                financialArr.push(res)
            })

            return financialArr
        },
        addInfo() {
            return [
                {
                    id: 0,
                    name: '본인 소유',
                },
                {
                    id: 1,
                    name: '부모님 소유',
                },
                {
                    id: 2,
                    name: '형제 소유',
                },
            ]
        },
    },
    methods: {
        onInputFinance(val) {
            this.$set(this.profile, 'finance', val)
            // this.profileChanged = true
        },
        deleteIntro() {
            this.$set(this.profile, 'intro', null)
            // this.profileChanged = true
        },
        getVal(key) {
            if (key !== 'car' && [null, ''].includes(this.profile[key])) return 'NO_VALUE'

            if (key.includes('house_payment')) {
                return this.profile.house_payment === '자가'
                    ? `${this.profile.house_payment}, ${
                          (this.addInfo[this.profile.house_owner] || {}).name || '입력해주세요'
                      }`
                    : this.profile.house_payment
            }

            if (key === 'car') {
                if (this.profile.car_type === '없음') {
                    return '미보유'
                }
                if (!this.profile.car_type) return 'NO_VALUE'
                // return `${this.profile.car_type} (${this.profile.car_brand} - ${this.profile.car_model})`
                return `${this.profile.car_type} (${this.profile.car_model})`
            }

            if (this.$profile.needMoneyInput(key)) {
                if (this.profile[key] === null) return 'NO_VALUE'

                return this.profile[key] > 0
                    ? this.$krwParsed(this.profile[key])
                    : `${this.$translate(this.$case.toConst(key)).toUpperCase()} 없음`
            }

            return this.profile[key]
        },
        async onClickProfileItem(key) {
            // modal
            if (key === 'car') {
                const item = await this.$modal.custom({
                    component: 'ModalProfileItemSteps',
                    options: {
                        model: key,
                        headerTitle: this.$translate(this.section.title.toUpperCase()),
                    },
                })

                this.$set(this.profile, 'car_type', item[0].name)
                this.$profile.updateProfileOptions('car_type', item[0])
                if (item.length > 1) {
                    this.$set(this.profile, 'car', 1)
                    // this.$set(this.profile, 'car_brand', item[1].name)
                    this.$set(this.profile, 'car_model', item[1].name)
                    // this.$profile.updateProfileOptions('car_brand', item[1])
                } else {
                    this.$set(this.profile, 'car', 0)
                    this.$set(this.profile, 'car_model', '')
                }

                return
            }

            // stack-router
            this.$unregisterBackHandler()
            if (this.$profile.needMoneyInput(key)) {
                this.$stackRouter.push({
                    name: 'EditProfileDetailFinance',
                    props: {
                        type: key,
                        section: this.section.title,
                    },
                })
            } else {
                if (key.includes('house_')) {
                    this.$stackRouter.push({
                        name: 'EditProfileDetailHouse',
                        props: {
                            type: key,
                        },
                    })

                    return
                }
                if (key === 'finance') {
                    this.$stackRouter.push({
                        name: 'EditProfileText',
                        props: {
                            type: key,
                            section: this.section.title,
                        },
                    })
                }
            }
        },
    },
}
</script>
<style scoped lang="scss">
//.intro {
//    border: 1px solid $grey-03;
//    padding: 12px;
//    border-radius: 8px;
//}
</style>
