<template>
    <div class="edit-profile-items">
        <!-- <div v-if="me.profile.status === 'confirmed'" class="status10-edit-description">
            <ul class="status10-edit-items">
                <li class="list-item">별표시(<span class="star">*</span>)는 필수 입력사항 입니다.</li>
                <li class="list-item">프로필 변경은 직접 가능합니다. 변경된 내용은 매니저 확인 후 반영됩니다.</li>
            </ul>
        </div>
        <div v-else class="required-description">
            *해당 표시는 <span class="blue-highlight-text">필수입력사항</span> 입니다.
        </div> -->
        <Personal :section="sections[0]" />
        <!-- <Education :section="sections[1]" />
        <Career :section="sections[2]" />
        <Financial :section="sections[3]" />
        <Family :section="sections[4]" /> -->
        <!-- <Marriage :section="sections[5]" />
        <OnlyToManager :section="sections[6]" /> -->
    </div>
</template>

<script>
import Personal from '@/routes/my-page/components/profile-sections/Personal'
import Financial from '@/routes/my-page/components/profile-sections/Financial'
import Marriage from '@/routes/my-page/components/profile-sections/Marriage'
import Career from '@/routes/my-page/components/profile-sections/Career'
import Education from '@/routes/my-page/components/profile-sections/Education'
import Family from '@/routes/my-page/components/profile-sections/Family'
import OnlyToManager from '@/routes/my-page/components/profile-sections/OnlyToManager'

export default {
    name: 'EditProfilePageV2',
    props: ['sections', 'required'],
    components: {
        Personal,
        Financial,
        Marriage,
        Career,
        Education,
        Family,
        OnlyToManager,
    },
    data: () => ({}),
    computed: {
        me() {
            return this.$store.getters.me
        },
        profile() {
            return this.$store.getters.profile
        },
    },
}
</script>
<style scoped lang="scss">
// .status10-edit-description {
//     background-color: #f7f8f9;
//     border-radius: 12px;
//     margin: 24px 16px 0;
//     padding: 8px 12px;
//     font-size: 14px;
//     line-height: 24px;
//     .status10-edit-items {
//         display: list-item;
//         margin-left: 16px;
//         color: #111111;
//         .list-item {
//             display: list-item;
//             list-style-type: disc;
//             .star {
//                 color: $blue-primary;
//             }
//         }
//     }
// }
</style>
