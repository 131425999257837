var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"personal section"},[_c('h3',{staticClass:"title"},[_vm._v("프로필")]),_vm._l((_vm.personal),function(item,idx){return _c('div',{key:`personal-${idx}`,staticClass:"profile-item",on:{"click":function($event){return _vm.onClickProfileItem(item.key)}}},[(item.key === 'intro')?[_c('div',{staticClass:"intro-wrapper w-100",on:{"click":function($event){return _vm.onClickProfileItem(item.key)}}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center"}},[_c('div',{staticClass:"label m-b-8",staticStyle:{"width":"fit-content !important"},domProps:{"innerHTML":_vm._s(_vm.$translate(_vm.$case.toConst(item.key)))}}),_c('div',{staticClass:"flex-row"},[(item.pur)?_c('div',{staticClass:"pur-icon"},[_vm._v("심사중")]):_vm._e()])]),_c('div',{staticClass:"w-100 intro",class:{
                        'c-black': _vm.profile.intro,
                        'c-grey': !_vm.profile.intro,
                    },domProps:{"innerHTML":_vm._s(_vm.profileIntro || _vm.placeholderIntro)}})])]:[_c('div',{staticClass:"profile-content"},[_c('div',{staticClass:"label p-l-20 m-b-4",domProps:{"innerHTML":_vm._s(_vm.$translate(_vm.$case.toConst(item.key)))}}),_c('div',{staticClass:"item"},[_c('div',{staticClass:"btn-selector hi p-l-20",class:{
                            'input-filled': _vm.getVal(item.key) !== 'NO_VALUE',
                            'no-input': _vm.getVal(item.key) === 'NO_VALUE',
                        },domProps:{"innerHTML":_vm._s(_vm.$translate(_vm.getVal(item.key, item.pur)))}})])]),_c('div',{staticClass:"go-detail"},[(item.pur || _vm.purOngoing.includes(item.key))?_c('div',{staticClass:"pur-icon"},[_vm._v("심사중")]):_vm._e(),(item.key !== 'age')?_c('img',{staticClass:"m-r-6",attrs:{"src":require(`@/assets/images/icons/arrow_right.svg`),"width":"16px","height":"16px"}}):_vm._e()])]],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }