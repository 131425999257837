<template>
    <div class="family-items" v-if="familyItems.length">
        <div
            class="item-wrapper"
            @click="editItem(item)"
            :key="`family-item-${idx}`"
            v-for="(item, idx) in familyItems"
        >
            <template>
                <div class="job-wrapper">
                    <div class="job-status">
                        <div>{{ parseItemInfo(item).familyType }}</div>
                        <div class="job-button">
                            <div class="job-edit">
                                <img class="m-r-2" src="@/assets/images/icons/edit.png" width="16px" height="16px" />
                                <span class="edit-text" v-html="'수정'" />
                            </div>
                            <div class="job-delete" @click.stop="deleteItem(item)">
                                <img class="m-r-2" src="@/assets/images/icons/trash.png" width="16px" height="16px" />
                                <span class="delete-text" v-html="'삭제'" />
                            </div>
                        </div>
                    </div>
                    <div class="job-description" v-html="parseItemInfo(item).status" />
                    <div class="job-description" v-html="parseItemInfo(item).univStatus" />
                    <div class="job-description" v-html="parseItemInfo(item).companyJob" />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FamilyItem',
    props: {
        familyItems: Array,
        familyType: Object,
    },
    methods: {
        title(type) {
            const familyType = this.familyType[type].name

            return this.$translate(familyType.toUpperCase())
        },
        liveStatus(item) {
            return item.live_status === 0 ? '살아계심' : '작고'
        },
        parseItemInfo(item) {
            const familyType = this.familyType[item.family_type].name
            const status =
                item.family_type === 2 ? item.brotherhood_status : `${item.live_status === 1 ? '살아계심' : '작고'}`
            return {
                familyType: this.$translate(familyType.toUpperCase()),
                status: status,
                univStatus:
                    item.education_status < 3
                        ? this.$profile.educationStatus[item.education_status].title
                        : `${this.$profile.educationStatus[item.education_status].title}${
                              item.university_name ? `•${item.university_name}` : ''
                          }`,
                // companyJob: 'asdfasdf'
                companyJob: `${item.job_name}${item.company_name ? `•${item.company_name}` : ''}•${
                    item.job_status ? '현직' : '전직'
                }`,
            }
        },
        editItem(item) {
            const { family_type: familyType } = item

            this.$unregisterBackHandler()
            this.$stackRouter.push({
                name: 'EditProfileDetailFamily',
                props: {
                    familyType: this.$profile.familyType[familyType],
                    edit: item,
                },
            })
        },
        async deleteItem(item) {
            const idx = await this.$modal.basic({
                body: '내용을 삭제하시겠습니까?',
                buttons: [
                    {
                        label: '아니오',
                        class: 'btn-default',
                    },
                    {
                        label: '네',
                        class: 'btn-primary',
                    },
                ],
            })
            if (idx === 1) {
                const { family } = this.$store.getters.profile

                this.$set(
                    this.$store.getters.profile,
                    'family',
                    family.filter(f => f.id !== item.id)
                )
            }
        },
    },
}
</script>

<style scoped></style>
